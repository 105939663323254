<template class="text_google">
  <v-app>
    <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="text-right">
        <v-btn
          large
          rounded
          dark
          href="javascript:window.print()"
          class="title mr-2 rounded"
          color="info"
        >
          <v-icon class="mr-1" color="white" large>mdi-printer</v-icon>พิมพ์
        </v-btn>

  
        <v-btn
          @click.stop="
            exportToExcel('exportContent', 'ตำแหน่งว่างหลังการพิจารณาย้าย')
          "
          class="mr-1" color="green"
        >
          <v-icon>mdi-file-excel</v-icon>&nbsp; Export to excel
        </v-btn>

      </v-toolbar-items>
    </v-system-bar>


    <div id="exportContent" style="mso-page-orientation: landscape;">
      <div
        class="page"
        v-for="(item, index) in transference_manage_locations"
        :key="index"
      >
        <div align="center">
          <div class="head">
            แบบบันทึกผล องค์ประกอบ ตัวชี้วัดและคะแนน
            การประเมินศักยภาพผู้บริหารสถานศึกษาที่ยื่นความประสงค์ขอย้าย
          </div>
          <div class="head">
            กรณีที่ 2 การย้ายกรณีเพื่อประโยชน์ของทางราชการ ประเภทที่ 1
            <u>การย้ายเพื่อพัฒนาคุณภาพการศึกษา</u>
          </div>
          <div class="head">
            กลุ่มที่ 1
            การย้ายผู้บริหารสถานศึกษาเพื่อพัฒนาคุณภาพการศึกษาในสถานศึกษาทั่วไป
          </div>
        </div>
        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="10%" class="regular16" align="left">ชื่อผู้ขอย้าย</td>
            <td width="50%" class="regular16 td_line" align="center">
              {{ item.title_s }}{{ item.frist_name }} {{ item.last_name }}
            </td>
            <td width="10%" class="regular16" align="left">ตำแหน่ง</td>
            <td width="30%" class="regular16 td_line" align="center">
              {{ item.position_name }}
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="10%" class="regular16" align="left">วิทยาลัย</td>
            <td width="90%" class="regular16 td_line" align="left">
              {{ item.college_name }}
            </td>
          </tr>
        </table>

        <br />
        <table class="text_j" border="1" width="100%" align="center">
          <tr>
            <td id="tdhigth" width="8%" class="bold16" align="center">
              ตัวชี้วัดที่
            </td>
            <td width="34%" class="bold16" align="center">
              องค์ประกอบ/ตัวชี้วัด
            </td>
            <td width="10%" class="bold16" align="center">
              เกณฑ์การให้คะแนน
            </td>
            <td width="12%" class="bold16" align="center">คะแนนที่ได้รับ</td>
            <td width="35%" class="bold16" align="center">กรอบการพิจารณา</td>
          </tr>
          <!-- ข้อ 1 -->
          <tr>
            <td id="tdhigth" class="bold16 background" align="center">1</td>
            <td class="bold16 background pa-1" colspan="4" align="left">
              ความรู้ความสามารถในการบริหารจัดการศึกษา (คะแนน 10 คะแนน)
            </td>
          </tr>
          <tr>
            <td class="bold16" rowspan="5" align="center">1.1</td>
            <td class="bold16 pl-1" colspan="3" align="left">
              ผลงานและรางวัลที่เกิดกับสถานศึกษา 5 คะแนน
            </td>
            <td class="regular16 pa-1" rowspan="5" align="left">
              <p style="text-indent :1em;">
                พิจารณาจากสำเนาเอกสารหลักฐานที่ระบุชื่อของข้าราชการครูและบุคลากรทางการศึกษา
                ในสถานศึกษา หรือชื่อสถานศึกษา ที่ได้รับรางวัลย้อนหลังไม่เกิน 5
                ปี นับถึงวันสุดท้ายของการยื่นคำร้องขอย้าย
                ซึ่งแสดงให้เห็นถึงผลงานและรางวัลที่เกิดจากการปฏิบัติงานในหน้าที่
                ขณะดำรงตำแหน่งผู้บริหารสถานศึกษาในสถานศึกษา
              </p>
              <p style="text-indent :1em;">
                ทั้งนี้
                ผู้ประสงค์ขอย้ายต้องลงลายมือชื่อรับรองสำเนาถูกต้องของเอกสารประกอบการพิจารณาทุกฉบับ
              </p>
            </td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              1) ระดับสถานศึกษา และระดับชาติ
            </td>
            <td class="regular16" align="center">5</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              2) ระดับสถานศึกษา และระดับภาค/ สถาบันการอาชีวศึกษา
            </td>
            <td class="regular16" align="center">4</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              3) ระดับสถานศึกษา และระดับจังหวัด
            </td>
            <td class="regular16" align="center">3</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">4) ระดับสถานศึกษา</td>
            <td class="regular16" align="center">2</td>
          </tr>
          <tr>
            <td class="bold16" rowspan="3" align="center">1.2</td>
            <td class="bold16 pl-1" colspan="3" align="left">
              วิทยฐานะ 5 คะแนน
            </td>
            <td class="regular16 pa-1" rowspan="3" align="left">
              <p style="text-indent :1em;">
                พิจารณาจากสำเนา ก.พ.7 หรือ ก.ค.ศ. 16 ที่ลงรายการครบถ้วน
                สมบูรณ์เป็นปัจจุบัน
                โดยมีเจ้าหน้าที่งานทะเบียนประวัติลงลายมือชื่อ รับรองความถูกต้อง
              </p>
            </td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              1) เชี่ยวชาญ ขึ้นไป
            </td>
            <td class="regular16" align="center">5</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              2) ชำนาญการพิเศษ
            </td>
            <td class="regular16" align="center">3</td>
          </tr>

          <!-- ข้อ 2 -->
          <tr>
            <td id="tdhigth" class="bold16 background" align="center">2</td>
            <td class="bold16 background pa-1" colspan="4" align="left">
              ประสบการณ์ในการบริหารสถานศึกษา (คะแนน 5 คะแนน)
            </td>
          </tr>
          <tr>
            <td class="bold16" rowspan="4" align="center"></td>
            <td class="bold16 pl-1" colspan="3" align="left">
              ระยะเวลาการดำรงตำแหน่งผู้อำนวยการสถานศึกษา
            </td>
            <td class="regular16 pa-1" rowspan="4" align="left">
              <p style="text-indent :1em;">
                พิจารณาจากสำเนา ก.พ.7 หรือ ก.ค.ศ. 16
                ที่ลงรายการครบถ้วนสมบูรณ์เป็นปัจจุบัน
                โดยมีเจ้าหน้าที่งานทะเบียนประวัติลงลายมือชื่อรับรองความถูกต้อง
              </p>
              <p style="text-indent :1em;">
                การนับระยะเวลาการดำรงตำแหน่ง
                ให้นับตั้งแต่วันที่มีคำสั่งแต่งตั้งให้ดำรงตำแหน่งผู้อำนวยการสถานศึกษา
                โดยนับถึงวันสุดท้ายของการยื่นคำร้องขอย้าย
              </p>
              <p style="text-indent :1em;">
                ทั้งนี้ หากมีเศษของปี ตั้งแต่ 6 เดือนขึ้นไป นับเป็น 1 ปี
              </p>
            </td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              1) ตั้งแต่ 10 ปีขึ้นไป
            </td>
            <td class="regular16" align="center">5</td>
    
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              2) 7-9 ปี
            </td>
            <td class="regular16" align="center">4</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              3) 4-6 ปี
            </td>
            <td class="regular16" align="center">3</td>            
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>

        <p style="page-break-after: always;">&nbsp;</p>

        <table class="text_j" border="1" width="100%" align="center">
          <tr>
            <td id="tdhigth" width="8%" class="bold16" align="center">
              ตัวชี้วัดที่
            </td>
            <td width="34%" class="bold16" align="center">
              องค์ประกอบ/ตัวชี้วัด
            </td>
            <td width="10%" class="bold16" align="center">
              เกณฑ์การให้คะแนน
            </td>
            <td width="12%" class="bold16" align="center">คะแนนที่ได้รับ</td>
            <td width="35%" class="bold16" align="center">กรอบการพิจารณา</td>
          </tr>

          <!-- ข้อ 3 -->
          <tr>
            <td id="tdhigth" class="bold16 background" align="center">3</td>
            <td class="bold16 background pa-2" colspan="4" align="left">
              ผลงานที่เกิดจากการปฏิบัติงานในหน้าที่/คุณภาพการปฏิบัติงาน (คะแนน
              30 คะแนน)
            </td>
          </tr>
          <tr>
            <td class="bold16" align="center"></td>
            <td class="bold16 pl-5" align="left">
              ผลงานเชิงประจักษ์และเป็นที่ยอมรับในการพัฒนาสถานศึกษา
            </td>
            <td class="regular16" align="center">
              30
            </td>
            <td class="regular16 pl-5" align="left"></td>
            <td class="regular16 pa-1" align="left">
              <p style="text-indent :1em;">
                พิจารณาจากความสำเร็จในการบริหารตามภาระงานและนโยบายตามที่ได้รับมอบหมาย
                ที่แสดงถึงความรู้ ความสามารถในการพัฒนาสถานศึกษา
                ที่สามารถขับเคลื่อนนโยบาย และจุดเน้นสู่ความสำเร็จ การมีส่วนร่วม
                และการแก้ไขปัญหา การใช้นวัตกรรมการบริหาร
                โดยเห็นผลเป็นที่ประจักษ์ และได้รับการยอมรับ
                โดยให้เขียนบรรยายแสดงผลงานในการพัฒนาสถานศึกษาประกอบด้วยหัวข้อ
                ดังนี้ <br />
                1. สาระสำคัญของผลงานโดยสรุป <br />
                2. กิจกรรมที่ทำและเนื้อหาของงานที่ปฏิบัติ <br />
                3. ผลผลิตและผลลัพธ์ที่ได้รับ <br />
                4. ประโยชน์ของผลงาน <br />
                5. การได้รับการยอมรับของผลงาน <br />
              </p>
              <p style="text-indent :1em;">
                ทั้งนี้ เนื้อหาความยาวไม่เกิน 5 หน้ากระดาษ A4 แบบอักษร TH
                SarabunIT๙ หรือ TH SarabunPSK ขนาดตัวอักษร 16 พอยท์
                และแนบหลักฐาน/เอกสารประกอบการพิจารณา รวมแล้วไม่เกิน10 หน้ากระดาษ
                A4 (กรรมการจะพิจารณาเอกสารตามจำนวนที่กำหนดเท่านั้น)
              </p>
            </td>
          </tr>

          <!-- ข้อ 4 -->
          <tr>
            <td id="tdhigth" class="bold16 background" align="center">4</td>
            <td class="bold16 background pa-2" colspan="4" align="left">
              วิสัยทัศน์ในการบริหารจัดการศึกษา (คะแนน 50 คะแนน)
            </td>
          </tr>
          <tr>
            <td class="regular16" align="center"></td>
            <td class="bold16 pl-5" align="left">
              วิสัยทัศน์ในการพัฒนาสถานศึกษาสู่คุณภาพ
            </td>
            <td class="regular16" align="center">
              50
            </td>
            <td class="regular16 pl-5" align="left"></td>
            <td class="regular16 pa-1" align="left">
              <p style="text-indent :1em;">
                พิจารณาจากการเขียนวิสัยทัศน์ในการพัฒนาสถานศึกษาที่จะขอย้ายไปดำรงตำแหน่ง
                โดยกำหนด ให้เขียนวิสัยทัศน์ในเรื่อง การพัฒนาสถานศึกษา สู่คุณภาพ
                ตามเป้าหมาย วัตถุประสงค์การพัฒนาของสถานศึกษา มีหัวข้อ ดังนี้
              </p>

              1. หลักการและเหตุผล <br />
              2. วิสัยทัศน์ <br />
              3. พันธกิจ <br />
              4. กระบวนการ/มาตรการหรือแนวทางที่นำไปสู่การปฏิบัติได้จริง <br />
              5. ผลที่คาดว่าจะได้รับ <br />
              6. ตัวชี้วัดความสำเร็จ <br />
              <p style="text-indent :1em;">
                ทั้งนี้ เนื้อหาความยาวไม่เกิน 5 หน้ากระดาษ A4 แบบอักษร TH
                SarabunIT๙ หรือ TH SarabunPSK ขนาดตัวอักษร 16 พอยท์
                โดยไม่ต้องมีเอกสารหลักฐานประกอบ (กรรมการจะพิจารณาเอกสาร
                ตามจำนวนที่กำหนดเท่านั้น)
              </p>
            </td>
          </tr>
        </table>

          <!-- ข้อ 5 -->
 <p style="page-break-after: always;">&nbsp;</p>

        <table class="text_j" border="1" width="100%" align="center">
          <tr>
            <td id="tdhigth" width="8%" class="bold16" align="center">
              ตัวชี้วัดที่
            </td>
            <td width="34%" class="bold16" align="center">
              องค์ประกอบ/ตัวชี้วัด
            </td>
            <td width="10%" class="bold16" align="center">
              เกณฑ์การให้คะแนน
            </td>
            <td width="12%" class="bold16" align="center">คะแนนที่ได้รับ</td>
            <td width="35%" class="bold16" align="center">กรอบการพิจารณา</td>
          </tr>

          <tr>
            <td id="tdhigth" class="bold16 background" align="center">5</td>
            <td class="bold16 background pa-2" colspan="4" align="left">
              การประพฤติตน (คะแนน 5 คะแนน)
            </td>
          </tr>
          <tr>
            <td class="regular16" rowspan="5" align="center"></td>
            <td class="bold16 pl-1" colspan="3" align="left">
              การรักษาวินัยและจรรยาบรรณวิชาชีพ
            </td>
            <td class="regular16 pa-1" rowspan="5" align="left">
              <p style="text-indent :1em;">
                พิจารณาจากสำเนา ก.พ.7 หรือ ก.ค.ศ. 16 ที่เป็นปัจจุบัน ย้อนหลัง 5
                ปี นับถึงวันแรกของกำหนดการยื่นคำร้องขอย้าย
                โดยมีเจ้าหน้าที่งานทะเบียนประวัติลงลายมือชื่อรับรองความถูกต้อง
              </p>

              <p style="text-indent :1em;">
                ทั้งนี้ ผู้ใดได้รับการล้างมลทิน ตาม พ.ร.บ. ล้างมลทิน ให้ถือว่า
                เป็นผู้เคยถูกลงโทษทางวินัยในระดับโทษนั้นๆ
              </p>
            </td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              1) ไม่เคยถูกลงโทษทางวินัย หรือ ทางจรรยาบรรณวิชาชีพ
            </td>
            <td class="regular16" align="center">5</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              2) เคยถูกลงโทษภาคทัณฑ์ หรือ เคยถูกตักเตือนทางจรรยาบรรณวิชาชีพ
            </td>
            <td class="regular16" align="center">4</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              3) เคยถูกลงโทษตัดเงินเดือน หรือ เคยถูกภาคทัณฑ์ทางจรรยาบรรณวิชาชีพ
            </td>
            <td class="regular16" align="center">3</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              4) เคยถูกลงโทษทางวินัย ลดเงินเดือน
              หรือเคยถูกพักใช้ใบอนุญาตประกอบวิชาชีพ
            </td>
            <td class="regular16" align="center">2</td>
          </tr>
          <tr>
            <td
              id="tdhigth"
              colspan="2"
              class="bold16 background"
              align="center"
            >
              <h3>รวม</h3>
            </td>
            <td class="bold16" align="center">100</td>
            <td></td>
            <td class="background"></td>
          </tr>
        </table>

        <br />
        <table width="100%">
          <tr>
            <td width="10%"></td>
            <td width="90%" class="bold16"><u>ความเห็นของคณะกรรมการ</u></td>
          </tr>
        </table>

        <table width="100%">
          <tr>
            <td width="10%"></td>
            <td width="20%" align="left" class="bold16">
              <v-icon large class="pa-2">mdi-checkbox-blank-outline</v-icon>ผ่าน
            </td>
            <td width="70%" align="left" class="bold16">
              <v-icon large class="pa-2">mdi-checkbox-blank-outline</v-icon
              >ไม่ผ่าน
            </td>
          </tr>
        </table>

        <table width="100%">
          <tr>
            <td width="50%" align="left"></td>
            <td width="50%" align="center">
              <h3>ลายมือชื่อ กรรมการประเมิน</h3>
            </td>
          </tr>
        </table>

        <br />

        <table width="100%" class="text_j">
          <tr>
            <td width="50%" align="left"></td>
            <td width="50%" align="center">
              ลงชื่อ......................................................................
            </td>
          </tr>
          <tr>
            <td width="50%" align="left"></td>
            <td width="50%" align="center">
              (...........................................................................)
            </td>
          </tr>
          <tr>
            <td width="50%" align="left"></td>
            <td width="50%" align="center">
              ตำแหน่ง.......................................................................
            </td>
          </tr>
          <tr>
            <td width="50%" align="left"></td>
            <td width="50%" align="center">
              วันที่....................../........................./.......................
            </td>
          </tr>
        </table>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    transference_manage_locations: [],
    valid: true
  }),

  async mounted() {
    await this.sweetAlertLoading()
    await this.searchTimeYear();
    Swal.close();

  },

  methods: {
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    async searchTimeYear() {
      this.loading = true;
      let result = await this.$http
        .post("transference_manage.php", {
          ApiKey: this.ApiKey,
          manage_time_s: this.manage_times,
          manage_year_s: this.manage_years,
          position_name: "ผู้อำนวยการวิทยาลัย",
          manage_case_move: "quality"
        })
        .finally(() => (this.loading = false));
      this.transference_manage_locations = result.data;
      
    },

    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    },

    exportToExcel(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Excel</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msexcel"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-excel;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".xls" : "document.xls";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    }
  },
  props: {
    source: String
  },

  computed: {
    manage_times() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[6];
    },
    manage_years() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[7];
    }
  }
};
</script>
<style>
@import url(
  https://fonts.googleapis.com/css?family=Prompt:400,300|Roboto:400,300&subset=latin,thai);

body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.textAlignVer {
  display: block;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  width: 20px;
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 5;
}

u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}

p {
  line-height: 1;
  margin: 0%;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 21cm;
  min-height: 29.7cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 0.5cm;
  padding-right: 0.5cm;
  padding-bottom: 0.5cm;
  padding-left: 0.5cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
  line-height: 1;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular14 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
.BlackBold10 {
  font-weight: bold;
}

.textAlignVer {
  display: block;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  width: 20px;
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 5;
}

#content {
  display: table;
}

.background {
  background-color: rgb(164, 161, 161);
}
#tdhigth {
  height: 50px;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    border: initial;
    border-radius: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding: initial;
    page-break-after: always;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .noprint {
    display: none;
  }
}
</style>
